<template>
  <div>
    <h1>Danh mục</h1>
    <CategoryTable />
  </div>
</template>

<script>
import CategoryTable from '@/components/CategoryTable.vue';

export default {
  name: 'Category',
  components: {
    CategoryTable
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
