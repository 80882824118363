<template>
  <div>
    <h1>Danh sách dịch vụ</h1>
    <ServiceTable />
  </div>
</template>

<script>
import ServiceTable from '@/components/ServiceTable.vue';

export default {
  name: 'About',
  components: {
    ServiceTable
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
