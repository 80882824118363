<template>
    <div class="login-container">
      <h2>Login</h2>
      <el-form @submit.native.prevent="handleLogin">
        <el-form-item>
          <el-input v-model="username" placeholder="Username"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="password" placeholder="Password"></el-input>
        </el-form-item>
        <el-button type="primary" @click="handleLogin">Login</el-button>
      </el-form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        username: '',
        password: '',
      };
    },
    methods: {
      async handleLogin() {
        try {
          const response = await axios.post('/api/login', {
            username: this.username,
            password: this.password,
          });
          const token = response.data.token;
          localStorage.setItem('authToken', token);  // Save token to localStorage
  
          // Optionally, navigate to another page
          this.$router.push({ name: 'Dashboard' });
        } catch (error) {
          console.error('Login failed:', error);
        }
      },
    },
  };
  </script>
  
  <style>
  .login-container {
    width: 300px;
    margin: 100px auto;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  </style>
  