<template>
  <div>
    <h1>Danh sách lịch hẹn</h1>
    <AppointmentTable />
  </div>
</template>

<script>
import AppointmentTable from '@/components/AppointmentTable.vue';

export default {
  name: 'Appointment',
  components: {
    AppointmentTable
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
