<template>
  <div>
    <h1>Danh sách Góp ý</h1>
    <CommentTable />
  </div>
</template>

<script>
import CommentTable from '@/components/CommentTable.vue';

export default {
  name: 'Comment',
  components: {
    CommentTable
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
