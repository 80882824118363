<template>
  <div class="service-table">
    <el-button
      type="primary"
      @click="openDialog(null)"
      style="float: right"
      class="add-service-btn"
      >Add Service</el-button
    >
    <el-table :data="services" style="width: 100%">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="name" label="Tên dịch vụ"></el-table-column>
      <el-table-column prop="description" label="Mô tả"></el-table-column>
      <el-table-column prop="price" label="Giá dịch vụ"></el-table-column>
      <el-table-column
        prop="priceDiscount"
        label="Giá sau khi giảm"
      ></el-table-column>
      <el-table-column label="Image">
        <template slot-scope="scope">
          <img
            :src="scope.row.image"
            alt="Service Image"
            class="service-image"
            v-if="scope.row.image"
          />
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template slot-scope="scope">
          <el-button size="mini" @click="openDialog(scope.row)">Sửa</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="deleteService(scope.row.id)"
            >Xoá</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
      <el-form :model="editedItem" ref="form" class="service-form">
        <el-form-item label="Mã dịch vụ">
          <el-input v-model="editedItem.code"></el-input>
        </el-form-item>
        <el-form-item label="Tên dịch vụ">
          <el-input v-model="editedItem.name"></el-input>
        </el-form-item>
        <el-form-item label="Mô tả">
          <el-input v-model="editedItem.description"></el-input>
        </el-form-item>
        <el-form-item label="Giá gốc">
          <el-input v-model="editedItem.price"></el-input>
        </el-form-item>
        <el-form-item label="Giá khuyến mại">
          <el-input v-model="editedItem.priceDiscount"></el-input>
        </el-form-item>
        <el-form-item label="Chọn ảnh đại diện">
          <el-upload
            class="upload-demo"
            action="api/v1/news/upload"
            :http-request="uploadImage"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :on-remove="handleRemove"
            accept="image/*"
          >
            <el-button slot="trigger" size="small" type="primary"
              >Select Image</el-button
            >
          </el-upload>
          <img
            style="height: 200px; object-fit: cover; margin-left: 15px; float: left"
            :src="editedItem.image"
            alt="Thumbnail Image"
            class="preview-image"
            v-if="editedItem.image"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveService">Save</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { uploadImageAPI } from "../api";

const BASE_URL = 'https://www.ranghanhphuc.com/';

export default {
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      services: [],
      thumbnailImage: "",
      editedIndex: -1,
      editedItem: {
        id: null,
        code: "",
        name: "",
        price: 0,
        priceDiscount: 0,
        description: "",
        image: "",
      },
      defaultItem: {
        id: null,
        code: "",
        name: "",
        price: 0,
        priceDiscount: 0,
        description: "",
        image: "",
      },
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    openDialog(item) {
      this.dialogTitle = item ? "Sửa dịch vụ" : "Thêm dịch vụ";
      this.editedIndex = this.services.indexOf(item);
      console.log(item)
      this.editedItem = item ? { ...item } : { ...this.defaultItem };
      this.dialogVisible = true;
    },
    saveService() {
      if (this.editedIndex > -1) {
        // Call API to update service
        axios
          .put(`${BASE_URL}api/v1/service`, this.editedItem)
          .then((response) => {
            console.log(response);
            this.dialogVisible = false; // Close dialog
            this.fetchServices(); // Reload data
          })
          .catch((error) => {
            console.error("Error updating service:", error);
          });
      } else {
        // Call API to create new service
        axios
          .post(`${BASE_URL}api/v1/service`, this.editedItem)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.services.push(this.editedItem);
            this.dialogVisible = false; // Close dialog
            this.fetchServices(); // Reload data
          })
          .catch((error) => {
            console.error("Error creating service:", error);
          });
      }
    },
    deleteService(id) {
      // Call API to delete service
      axios
        .delete(`${BASE_URL}api/v1/service/${id}`)
        .then((response) => {
          console.log(response);
          this.services = this.services.filter((service) => service.id !== id);
          this.fetchServices(); // Reload data
        })
        .catch((error) => {
          console.error("Error deleting service:", error);
        });
    },

    fetchServices() {
      axios
        .get(`${BASE_URL}api/v1/service`)
        .then((response) => {
          this.services = response.data;
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    },

    uploadImage({ file }) {
      const formData = new FormData();
      formData.append("file", file);
      uploadImageAPI(formData)
        .then((response) => {
          this.editedItem.image = response.data.url; // Set thumbnail URL
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    },
    handleUploadSuccess(file) {
      this.thumbnailImage.image = URL.createObjectURL(file.raw);
    },
    handleRemove() {
      this.thumbnailImage = "";
    },
  },
};
</script>

<style scoped>
.service-table {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-service-btn {
  margin-bottom: 20px;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  overflow: hidden;
}

.el-table th,
.el-table td {
  padding: 12px 16px;
}

.el-table th {
  background-color: #fafafa;
  text-align: left;
}

.el-dialog {
  border-radius: 8px;
}

.service-form .el-form-item {
  margin-bottom: 16px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 16px;
}

.dialog-footer .el-button {
  margin-left: 8px;
}

.service-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
